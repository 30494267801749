import './object_assign_polyfill';
import './promise_polyfill';
import './array_find_polyfill';
import './array_find_index_polyfill';
import './flat_map_polyfill';
import './stylesheets/shop';

document.addEventListener('DOMContentLoaded', () => {
  // set up CSRF form token to be sent for all AXIOS requests,
  // to avoid InvalidAuthenticityToken error from rails
  console.log('Initializing interceptor...');
  var csrfToken = $('meta[name="csrf-token"]').attr('content');
  App.activeAjaxRequests = 0;
  axios.interceptors.request.use(function (config) {
    config.headers = Object.assign({
      'X-CSRF-Token': csrfToken,
    }, config.headers);
    App.activeAjaxRequests++;
    return config;
  }, function (error) {
    App.activeAjaxRequests--;
    return Promise.reject(error);
  });
  axios.interceptors.response.use(function (response) {
    App.activeAjaxRequests--;
    return response;
  }, function (error) {
    App.activeAjaxRequests--;
    return Promise.reject(error);
  });
  console.log('Interceptor initialized!');
});
